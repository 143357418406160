import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import GetTouchForm from "../components/GetTouchForm"
import StickyBox from "react-sticky-box"
import ScrollspyNav from "react-scrollspy-nav"
import { Container, Row, Col, Breadcrumb } from "react-bootstrap"
import {
  CallIcon,
  ProcessIcon,
  ApplyIcon,
  BenifitsIcon,
  HandshakeIcon,
} from "../components/Icons"
import PatternImg from "../images/line-pattern.png"
import RtoBuildImg from "../images/rto-build.svg"
import ZigzagImg from "../images/zig-zag.svg"
import CallUs from "../images/call-us.svg"
import PaymentIcon from "../images/payment.svg"
import PayOffIcon from "../images/payoff.svg"
import OrderIcon from "../images/order-1.svg"
import ApplyRtoIcon from "../images/apply-rto-1.svg"
import CreditCheckImg from "../images/credit-check.svg"
import instantnApproveImg from "../images/in-appro.svg"
import { Section } from "../components/Section"

const Financing = ({location}) => {
  let formId = "Keep Your Metal Building Financial Planning Stable"
  return (
    <Layout location = { location }>
      <SEO title="Financing" />
      <section style={{ background: `url(${PatternImg})` }}>
        <Container className="position-relative">
          <Breadcrumb className="beadcrumbs-outer">
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item active>Financing</Breadcrumb.Item>
          </Breadcrumb>
        </Container>
      </section>
      <Section className="section rto-section" pt="100px" pb="100px">
        <Container>
          <Row>
            <Col xl={4}>
              <StickyBox offsetTop={40}>
                <div className="section-heading">
                  <h2 className="section-title">Financing</h2>
                  <span className="section-subtitle"></span>
                </div>
                <div className="section-content d-none d-lg-block">
                  <div className="wheeltab">
                    <ul className="rto-tabs">
                      {typeof window !== "undefined" ? (
                        <ScrollspyNav
                          scrollTargetIds={[
                            "section_1",
                            "section_2",
                            "section_3",
                            "section_4",
                          ]}
                          activeNavClass="is-active"
                        >
                          <li className="about-rto">
                            <a href="#section_1" className="text is-active">
                              <div className="text-inner">
                                <HandshakeIcon /> About Financing
                              </div>
                            </a>
                          </li>
                          <li className="process">
                            <a href="#section_2" className="text">
                              <div className="text-inner">
                                <ProcessIcon />
                                Our Proces
                              </div>
                            </a>
                          </li>

                          <li className="benfits">
                            <a href="#section_3" className="text">
                              <div className="text-inner">
                                <BenifitsIcon />
                                Benefits
                              </div>
                            </a>
                          </li>
                          <li className="get-started">
                            <a href="#section_4" className="text">
                              <div className="text-inner">
                                <ApplyIcon />
                                Get Started
                              </div>
                            </a>
                          </li>
                        </ScrollspyNav>
                      ) : null}
                    </ul>
                  </div>
                </div>
              </StickyBox>
            </Col>
            <Col xl={{ span: 7, offset: 1 }}>
              <div className="low-monthly-program tab-section" id="section_1">
                <img src={RtoBuildImg} className="rto-img" alt="financing" />
                <h4>Metal Building Financing</h4>
                <p>If you’re in the market for a metal building—whether it’s a carport, a steel barn, a utility shed, or more—you’re probably thrilled at the chance to purchase a steel shelter that can help you protect your valuables, store items, and make your life easier. But you’re also likely wondering just how much a quality, durable steel building might set you back financially.</p>
                <p>At Carports R Us, we do our very best to offer affordable, low-prices—but more than that, we understand that not every family, business, or individual is ready to purchase the metal building of their dreams outright. But that shouldn’t get in the way of investing in the metal building you’ve been waiting for. We’re proud to offer financing programs that make sense for you! Carports R Us has partnered with GreenSky to offer flexible finance programs that aren’t just easy to understand, but also simple and direct—making it easier than ever to finance the metal building you’ve been searching for!</p>
                <div className="cto-section">
                  <span className="title">
                    We invite you to contact our team at Carports R Us as quickly as
                    you can to apply for our financing for the metal
                    building of your dreams!
                  </span>
                  <a href="tel:8773304846" className="btn btn-link">
                    <CallIcon stroke="#CB0000" width="34px" height="34px" />
                    (877) 330-4846
                  </a>
                </div>
              </div>
              <div className="rto-process tab-section" id="section_2">
                <h4>The Financing Process—It’s Quick, Easy, and Affordable</h4>
                <p>The best part about our financing program with Carports R Us? It’s incredibly intuitive! No complex, intricate financing plans here—with Carports R Us, you’ll only find direct and simple plans that make purchasing quick, plans easy, and payments affordable. No matter what type of metal building you’re looking for, Carports R Us financing program can help you start investing ASAP, working with you step-by-step to ensure you’re matched with the metal building you need.</p>
                <ul className="rto-timeline-list">
                  <img src={ZigzagImg} className="zig-zag-img" alt="..." />
                  <li>
                    <div className="icon-box">
                      <img src={CallUs} alt="Call Us" />
                    </div>
                    <div className="content-box">
                      <h4 className="title title-underline">
                        Talk to Our Representative
                      </h4>
                    </div>
                  </li>
                  <li>
                    <div className="icon-box">
                      <img src={OrderIcon} alt="Order" />
                    </div>
                    <div className="content-box">
                      <h4 className="title title-underline">
                        Place Your Order
                      </h4>
                    </div>
                  </li>
                  <li>
                    <div className="icon-box">
                      <img src={ApplyRtoIcon} alt="Apply" />
                    </div>
                    <div className="content-box">
                      <h4 className="title title-underline">Apply For Financing</h4>
                    </div>
                  </li>
                  <li>
                    <div className="icon-box">
                      <img src={PaymentIcon} alt="Payment" />
                    </div>
                    <div className="content-box">
                      <h4 className="title title-underline">
                        Make a Small Payment
                      </h4>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="why-renting tab-section" id="section_3">
                <div className="section-heading">
                  <h4>Buying a Steel Building Has Never Been Easier—Or More Affordable!</h4>
                  <p>No matter the reason you’re looking to invest in a metal building, you deserve to purchase as soon as possible to streamline installation—why should money get in the way? At Carports R Us, we pride ourselves on offering the most affordable, best quality metal buildings around—but we also pride ourselves on presenting simple, direct, and easy financing plans for folks looking to install their metal building as quickly as possible, even if cash on hand isn’t a reality. We believe that—for many—a financing plan through Carports R Us is one of the best options to opt for. Why? Here are just a few of the amazing benefits that go hand-in-hand when it comes to purchasing a steel building through our financing options!</p>
                </div>
                <div className="section-content">
                  <ul className="why-renting-list">
                    <li>
                      <div className="icon-box">
                        <img src={CreditCheckImg} alt="Credit Check" />
                      </div>
                      <div className="content-box">
                        <h4 className="title title-underline">It’s Quick</h4>
                        <p>We’ll never make you wait with our financing plans. As professionals in the industry, we have our process locked and loaded, which means you won’t have to deal with long lead times or any backup when it comes time for your payment plan. We’re fast, we’re efficient, and we’re here to make your financing plan as quick and easy as possible.</p>
                      </div>
                    </li>
                    <li>
                      <div className="icon-box">
                        <img src={instantnApproveImg} alt="Pay off" />
                      </div>
                      <div className="content-box">
                        <h4 className="title title-underline">
                        It’s Easy
                        </h4>
                        <p>Partnering with GreenSky for a financing plan is a breeze. As our valued customer, we always want to ensure that your experience is seamless, so our financing plans are no different. The financing process is simple, easy, and incredibly intuitive—even if you’ve never financed before. We’re with you every step of the way to walk you through plans, options, and more.</p>
                      </div>
                    </li>
                    <li>
                      <div className="icon-box">
                        <img src={PayOffIcon} alt="No Early Payoff Penalties" />
                      </div>
                      <div className="content-box">
                        <h4 className="title title-underline">It’s Transparent</h4>
                        <p>No hidden fees here! At the end of the day, the only thing that matters to us is that you’re receiving the metal building of your dreams. For us, there’s no benefit to hide fees, write things in fine print, or make your life more complicated. The trickier it is, the longer you’ll have to wait to get your prefab metal building—and that’s the last thing we want to happen! Our financing plans are direct and thorough—we can guarantee you’ll never deal with any surprise fees, costs, or contracts!</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="financing-process tab-section" id="section_4">
              <div className="section-section">
                  <h4 className="title">Keep Your Metal Building Financial Planning Stable</h4>
                  <p>Break away from the status quo & buy your dream metal building today with easy and actionable financing program.</p>
                  <div className="form-section">
                    <GetTouchForm location = { location } formId = { formId } />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Section>
    </Layout>
  )
}

export default Financing
